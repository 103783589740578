import api from '@/services/api';
import queryString from 'query-string';

class RoomService {
  createReservation(params) {
    return api
      .post('/rooms', params)
  }

  updateReservation(params) {
    return api
      .put(`/rooms/${params.id}`, params)
  }

  sendMailReservationStatus(params) {
    return api.post('/rooms/admin/status/send-mail', params)
  }

  cancelReservation(reservationId) {
    return api
      .put(`/rooms/${reservationId}/cancel`)
  }

  approveReservation(params) {
    return api
      .put(`/rooms/admin/approve`,params)
  }

  declineReservation(params) {
    return api
      .put(`/rooms/admin/decline`,params)
  }

  updateReservationTime(params) {
    return api
      .put(`/rooms/${params.id}/time`, params)
  }

  deleteReservation(reservation_id) {
    return api
      .delete(`/rooms/${reservation_id}`)
  }

  deleteManyReservation(params) {
    return api
      .post(`/rooms/admin/delete`, params)
  }

  reservations(params) {
    return api
      .get(`/rooms?${queryString.stringify(params)}`)
  }

  allReservations(params) {
    return api
      .get(`/rooms/all?${queryString.stringify(params)}`)
  }

  reservation(ticketId) {
    return api
      .get(`/rooms/${ticketId}`)
  }

  excel() {
    return api
      .get(`/rooms/admin/excel`,{responseType: 'blob'})
  }

  userReservations(userId) {
    return api
      .get(`/rooms/${userId}`)
  }

  myStatus(date_index) {
    return api
      .get(`/rooms/${date_index}/status/me`)
  }

  userStatus(date_index, user_id) {
    return api
      .get(`/rooms/${date_index}/status/${user_id}`)
  }

  status(date_index) {
    return api
      .get(`/rooms/${date_index}/status`)
  }
}

export default new RoomService();

import { Navigate } from "react-router";
import { useSelector } from "react-redux";

function PrivateRoute(props) {
  const currentUser = useSelector(state => state.user);

  const { basePath } = useSelector((state) => state.common);

  if(currentUser?.isLoggedIn){
    if(!currentUser.user?.isVerified){
      return <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} />
    } else if(props?.role === 'Admin' && !props.permissions.some(r=> currentUser.user.permissions.includes(r))) {
      return props.redirectPath ? <Navigate to={props.redirectPath} /> : <Navigate to={`/${basePath}`} />;
    }
  } else {
    return props.redirectPath ? <Navigate to={props.redirectPath} /> : <Navigate to={`/${basePath}`} />;
  }
  
  return props.children;
}

export default PrivateRoute;

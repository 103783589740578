import api from '@/services/api';
import queryString from 'query-string';

class TicketService {
  createTicket(params) {
    return api
      .post('/tickets', params)
  }

  updateTicket(params) {
    return api
      .put(`/tickets/${params.id}`, params)
  }

  sendMailTicketStatus(params) {
    return api.post('/tickets/admin/status/send-mail', params)
  }

  declineTicket(params) {
    return api
      .put(`/tickets/admin/decline`,params)
  }

  returnBuildUpTicket(ticketId) {
    return api
      .put(`/tickets/admin/return/${ticketId}/buildup`)
  }

  returnOfficialTicket(ticketId) {
    return api
      .put(`/tickets/admin/return/${ticketId}/official`)
  }

  approveBuildUpTicket(ticketId) {
    return api
      .put(`/tickets/admin/approve/${ticketId}/buildup`)
  }

  approveOfficialTicket(ticketId) {
    return api
      .put(`/tickets/admin/approve/${ticketId}/official`)
  }

  approveTicket(params) {
    return api
      .put(`/tickets/admin/approve`,params)
  }

  declineTicket(params) {
    return api
      .put(`/tickets/admin/decline`,params)
  }

  cancelTicket(ticketId) {
    return api
      .put(`/tickets/${ticketId}/cancel`)
  }

  deleteTicket(params) {
    return api
      .delete(`/tickets/${params.id}`)
  }

  deleteManyTicket(params) {
    return api
      .post(`/tickets/admin/delete`, params)
  }

  tickets(params) {
    return api
      .get(`/tickets?${queryString.stringify(params)}`)
  }

  allTickets(params) {
    return api
      .get(`/tickets/all?${queryString.stringify(params)}`)
  }

  ticket(ticketId) {
    return api
      .get(`/tickets/${ticketId}`)
  }

  excel() {
    return api
      .get(`/tickets/admin/excel`,{responseType: 'blob'})
  }

  existsEmail(email) {
    return api
      .get(`/tickets/emails/${email}/exists`)
  }

  userTickets(userId) {
    return api
      .get(`/tickets/${userId}`)
  }
}

export default new TicketService();

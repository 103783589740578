import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from '@/reducers/auth';
import common from '@/reducers/common';
import user from '@/reducers/user';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
};

export const rootReducer = combineReducers({
  common,
  auth,
  user
});

export default persistReducer(persistConfig, rootReducer);

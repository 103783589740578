import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmail } from 'validator';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Moment from 'react-moment';

import { createReservation, availableDateTime } from '@/actions/tour';
import { me } from '@/actions/auth';

import history from '@/helpers/history';

const initGuest = {
  name: '',
  company: '',
  position: ''
}

const initValue = {
  date: '',
  time: '',
  language: '',
  inviteNumber: 1,
  inviteSamsungEmployeesNumber: 0,
  host: {
    department: '',
    executiveName: '',
    executivePosition: '',
    onSiteContactName: '',
    onSiteContactMobile: ''
  },
  pic: {
    firstName: '',
    lastName: '',
    region: '',
    organization: '',
    gbm: '',
    department: '',
    email: '',
    countryCode: '',
    mobile: ''
  },
  site: {
    firstName: '',
    lastName: '',
    countryCode: '',
    mobile: ''
  },
  guests: [
    initGuest
  ]
}

export default function TourRerservationTemp() {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required field'),
    time: Yup.string().required('Required field'),
    language: Yup.string().required('Required field'),
    inviteNumber: Yup.number().min(1).typeError('Amount must be a number').required('Required field'),
    inviteSamsungEmployeesNumber: Yup.number().min(0).typeError('Amount must be a number').required('Required field'),
    host: Yup.object().shape({
      department: Yup.string().required('Required field'),
      executiveName: Yup.string().required('Required field'),
      executivePosition: Yup.string().required('Required field')
    }),
    pic: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      region: Yup.string().required('Required field'),
      organization: Yup.string().required('Required field'),
      gbm: Yup.string().required('Required field'),
      department: Yup.string().required('Required field'),
      email: Yup.string().email('Email is Invalid').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    site: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    guests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required field'),
        company: Yup.string().required('Required field'),
        position: Yup.string().required('Required field')
      })
    )
  });

  const formOptions = {
    defaultValues: initValue,
    resolver: yupResolver(validationSchema)
  };

  
  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields, append, update, remove } = useFieldArray({ name: 'guests', control });

  const [tourTimes, setTourTimes] = useState(null);
  const [isSameProfile, setIsSameProfile] = useState(false);
  const [isSameProfileSite, setIsSameProfileSite] = useState(false);

  const formData = watch();

  useEffect(() => {
    if(isSameProfile){
      dispatch(me())
      .then((res) => {
        // tour.pic = {
        //   firstName: res.firstName,
        //   lastName: res.lastName,
        //   email: res.email,
        //   region: res.region,
        //   organization: res.organization,
        //   countryCode: res.countryCode,
        //   mobile: res.mobile
        // }
        // setTour({...tour});
        setValue('pic', {
          firstName: res.firstName,
          lastName: res.lastName,
          region: res.region,
          organization: res.organization,
          gbm: '',
          department: '',
          email: res.email,
          countryCode: res.countryCode,
          mobile: res.mobile
        })
      })
    } else {
      setValue('pic', {
        firstName: '',
        lastName: '',
        region: '',
        organization: '',
        gbm: '',
        department: '',
        email: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isSameProfile])

  useEffect(() => {
    if(isSameProfileSite){
      dispatch(me())
      .then((res) => {
        setValue('site', {
          firstName: res.firstName,
          lastName: res.lastName,
          countryCode: res.countryCode,
          mobile: res.mobile
        })
      })
    } else {
      setValue('site', {
        firstName: '',
        lastName: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isSameProfileSite])

  useEffect(() => {
    dispatch(availableDateTime())
    .then(res => {
      setTourTimes(res)
    })
  }, []);

  // useEffect(() => {
  //   setValue('inviteNumber', fields.length)
  // }, [fields.length])
  const addGuest = () => {
    append({
      name: '',
      company: '',
      position: ''
    })
  }

  const removeGuest = (index) => {
    remove(index)
  }

  const onSubmit = (data) => {
    dispatch(createReservation(data))
    .then((res) => {
      history.navigate('/viptour/reservation-list', { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onChangeCheckbox = (e) => {
    if(e.target.checked){
      setIsSameProfile(true);
    }else{
      setIsSameProfile(false);
    }
  }

  const onChangeCheckboxSite = (e) => {
    if(e.target.checked){
      setIsSameProfileSite(true);
    }else{
      setIsSameProfileSite(false);
    }
  }
  const [lang, setLang] = useState('kr');
  return (
    <>
    <div className="mb40"/>
    <div className="container_body-tit">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <div style={{padding:'60px 0',textAlign:'center',borderBottom:'1px solid #000',lineHeight:'1.8'}}>
            <h4 style={{paddingBottom:'1em',fontWeight:'bold'}} className="ssone">Notice of Application Deadline</h4>
            <p className='mb10'>The first round of website applications is now closed.<br/>The second round will open on Saturday, August 24th, at 10:00 AM.</p>
            <p>Thank you.</p>
          </div>
        ) : (
          <div style={{padding:'60px 0',textAlign:'center',borderBottom:'1px solid #000',lineHeight:'1.8'}}>
            <h4 style={{paddingBottom:'1em',fontWeight:'bold'}}>사이트 신청 마감 안내</h4>
            <p className='mb10'>투어 신청이 많아 사이트 신청을 1차 마감합니다.<br/>2차 오픈은 8/24(토) 10:00 예정입니다.</p>
            <p style={{textAlign:''}}>
                감사합니다.
            </p>
          </div>
        )
      }
    </>
  );
}

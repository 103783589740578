import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { isEmail } from 'validator';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import iconWarning from '@assets/images/icon_warning.png';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import uuid from 'react-uuid'
import history from '@/helpers/history';

import { me } from '@/actions/auth';
import { createTicket, existsEmail } from '@/actions/ticket';

const initValue = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  division: '',
  region: '',
  organization: '',
  countryCode: '',
  mobile: '',
  checkEmail: false
}

export default function TicketRequest() {
  const dispatch = useDispatch();
//min(40, 'must be at least 40 characters')
  const validationSchema = Yup.object().shape({
    tickets: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
        lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
        email: Yup.string().email('Email is Invalid').required('Required field'),
        checkEmail: Yup.boolean().required('Please double check your email').oneOf([true], 'Please double check your email'),
        company: Yup.string().required('Required field'),
        division: Yup.string().required('Required field'),
        region: Yup.string().required('Required field'),
        organization: Yup.string().required('Required field'),
        countryCode: Yup.string().required('Required field'),
        mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
      })
    )
  });

  const formOptions = {
    defaultValues: {
      orno: uuid(),
      tickets: [ initValue ]
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ name: 'tickets', control });

  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isSameProfile, setIsSameProfile] = useState(false);
  const [isTicketNotice, setIsTicketNotice] = useState(false);
  const [isDupEmail, setIsDupEmail] = useState(false);
  const [isDupEmailInList, setIsDupEmailInList] = useState(false);
  const [dupEmails, setDupEmails] = useState('');

  const tickets = watch('tickets');

  useEffect(() => {
    if(isSameProfile){
      dispatch(me())
      .then((res) => {
        setValue(`tickets[0]`, {
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          company: res.company,
          division: res.division,
          region: res.region,
          organization: res.organization,
          countryCode: res.countryCode,
          mobile: res.mobile,
          checkEmail: false
        }, { shouldValidate: true })
      })
    } else {
      setValue(`tickets[0]`, {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        division: '',
        region: '',
        organization: '',
        countryCode: '',
        mobile: '',
        checkEmail: false
      })
    }
  }, [isSameProfile])

  const appendTicket = () => {
    append(initValue);
  }

  const deleteTicket = (index) => {
    remove(index);
  }

  const onSubmit = (data) => {
    setIsTicketNotice(true);
  }

  const onCreateTicket = () => {
    dispatch(createTicket({tickets}))
    .then((res) => {
      history.navigate('/ticket/ticket-list', { replace: true })
    })
    .catch(message => {
      if(message.indexOf('dup key') && message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.[0]){
        setIsDupEmail(true);
        setDupEmails(message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.[0])
      } else {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      }
    });
  }

  const checkEmail = (index) => {
    if(!isEmail(tickets[index].email) ) {
      setError(`tickets[${index}].email`, { message: 'please enter email' });
      toast.error('please enter email', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else {
      setIsDupEmailInList(false);
      setDupEmails('');
      setError(`tickets[${index}].email`, { message: '' });
    }

    for(let i=0; i < tickets.length; i++) {
      if(i !== index && tickets[i].email === tickets[index].email){
        setIsDupEmail(true);
        setIsDupEmailInList(true);
        setDupEmails(tickets[i].email);
        return;
      }
    }

    dispatch(existsEmail(tickets[index].email))
    .then((res) => {
      if(res.isExistsEmail){
        setError(`tickets[${index}].email`, { message: 'Duplicate email registration is prohibited\n(Proxy request is available)' });
        setIsDupEmail(true);
      }else{
        setValue(`tickets.${index}.checkEmail`, true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onChangeSameProfileCheckbox = (e) => {
    if(e.target.checked){
      setIsSameProfile(true);
    }else{
      setIsSameProfile(false);
    }
  }
  const [lang, setLang] = useState('kr');
  return (
    <>
    <div className="mb40"/>
    <div className="container_body-tit">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <div style={{padding:'60px 0',textAlign:'center',lineHeight:'1.8'}}>
            <h4 style={{paddingBottom:'1em',fontWeight:'bold'}} className="ssone">Notice of Application Deadline</h4>
            <p className='mb10'>
              Ticket applications are now closed.<br/>
              You can view the list of your ticket applications in the <a href='/ticket-list'>Ticket List</a> tab.<br/>
              For those who urgently need issuance,<br/>
              please contact Heali Hwang(<a href="mailto:h0302.hwang@samsung.com" className='ssone inner_link'>h0302.hwang@samsung.com</a>) (C.C Seulae Yang) via email & phone by Monday, August 26th.
            </p>
            <p>Thank you.</p>
          </div>
        ) : (
          <div style={{padding:'60px 0',textAlign:'center',lineHeight:'1.8'}}>
            <h4 style={{paddingBottom:'1em',fontWeight:'bold'}}>사이트 신청 마감 안내</h4>
            <p className='mb10'>
              티켓 신청이 마감되었습니다.<br/>
              티켓 신청 리스트는 <a href='/ticket-list'>Ticket List</a> 탭에서 확인 가능합니다.<br/>
              긴급 발행 필요하신 분은 월요일(8/26)일까지<br/>
              황혜리 프로(<a href="mailto:h0302.hwang@samsung.com" className='ssone inner_link'>h0302.hwang@samsung.com</a>) (C.C 양슬애 프로) 통해 메일과 전화 부탁드립니다.
            </p>
            <p style={{textAlign:''}}>
                감사합니다.
            </p>
          </div>
        )
      }
    </>
  );
}

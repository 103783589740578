const companyData = {
  Samsung: [
    'GMO',
    'VD / VD EBT',
    'DA',
    'MX',
    'DS',
    'Comm',
    'SR',
    'DPC',
    'Etc',
    // 'Other'
  ],
  Cheil: [
    'GMO',
    'VD / VD EBT',
    'DA',
    'MX',
    'DS',
    'Comm',
    'SR',
    'DPC',
    'Etc',
    // 'Other'
  ],
  // 'Third party(협력사)': [    
  //   'GMO',
  //   'VD / VD EBT',
  //   'DA',
  //   'MX',
  //   'DS',
  //   'Comm',
  //   'SR',
  //   'etc',
  //   'Other'
  // ]
}

export default companyData;

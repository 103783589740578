import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import setupInterceptors from '@/services/setupInterceptors';
import store from '@/store';
import krMsg from "./translations/kr.json";
import enMsg from "./translations/en.json";
import App from '@/App';

import 'react-toastify/dist/ReactToastify.css';
import '@/css/adm.css';
import '@/css/default.css';
import '@/css/bootstrap.css';
import '@/css/gnb.css';
import '@/css/font.css';
import '@/css/style.css';

if(process.env.NODE_ENV === 'production'){
  console.info(`
  =========================
  FRONT_VERSION : ${process.env.REACT_APP_VERSION}
  =========================\n
`)
}

const persistor = persistStore(store);

const rootNode = document.getElementById('root');

const locale = localStorage.getItem('locale') ?? 'en';
const messages = { ko: krMsg, en: enMsg }[locale];

ReactDOM.createRoot(rootNode).render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={locale} messages={messages}>
          <ToastContainer />
          <App />
        </IntlProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
);

setupInterceptors(store);

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const SET_BASE_PATH = 'SET_BASE_PATH';
export const CLEAR_BASE_PATH = 'CLEAR_BASE_PATH';

import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import faqKRData from '@/data/faqKR';
import faqENData from '@/data/faqEN';
import _ from 'lodash';
import history from '@/helpers/history';

export default function Faq() {
  const [lang, setLang] = useState('kr');
  const [selectedItemIndex, setSelectedItemIndex] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [faqKR, setFaqKR] = useState(null);
  const [faqEN, setFaqEN] = useState(null);
  const [list, setList] = useState(null);
  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    if(basePath === 'viptour') {      
      setFaqEN(_.filter(faqENData, row=> row.categoryCode === 'use' || row.categoryCode === 'vip'))
      setFaqKR(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'vip'))
      setList(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'vip'))
    } else if(basePath === 'room') {      
      setFaqEN(_.filter(faqENData, row=> row.categoryCode === 'use' || row.categoryCode === 'room'))
      setFaqKR(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'room'))
      setList(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'room'))
    } else {
      setFaqEN(_.filter(faqENData, row=> row.categoryCode === 'use' || row.categoryCode === 'ticket'))
      setFaqKR(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'ticket'))
      setList(_.filter(faqKRData, row=> row.categoryCode === 'use' || row.categoryCode === 'ticket'))
    }
  }, [basePath]);
  
  const selectFaq = index => {
    if(selectedItemIndex === index) {
      setSelectedItemIndex('')
    } else {
      setSelectedItemIndex(index)
    }
  }

  const selectLang = lang => {
    setLang(lang);

    if(selectedCategory != '-1'){
      setList(_.filter(lang === 'en' ? faqEN : faqKR,row=>row.category === Object.keys(_.groupBy(lang === 'en' ? faqEN : faqKR, row => row.category))[selectedCategory]))
    }else{
      setList(lang === 'en' ? faqEN : faqKR)
    }
  }

  const selectCategory = (e) => {
    setSelectedCategory(e.target.value);
    if(e.target.value != '-1'){
      setList(_.filter(lang === 'en' ? faqEN : faqKR,row=>row.category === Object.keys(_.groupBy(lang === 'en' ? faqEN : faqKR, row => row.category))[e.target.value]))
    }else{
      setList(lang === 'en' ? faqEN : faqKR)
    }
    setSelectedItemIndex('');
  }

  return (
    faqKR && faqEN && list && <div style={{minHeight:'calc(100vh - 138px)'}}>
      <div className='container_top ticket_top'>
        <div className='top-info top-info_cn'>
          <h2 className="top-title"><span className="spsans">Frequently asked questions</span></h2>
        </div>
      </div>
      <div className="container container_faq">
        <div className="container_body-tit">
          <div className='faq_tit_left_wrap'>
            <label><input type="radio" name="category" onChange={selectCategory} value={-1} checked={selectedCategory == -1}/>{lang === 'en' ? 'All' : '모두'}</label>
            {
              Object.keys(_.groupBy(lang === 'en' ? faqEN : faqKR, row => row.category)).map((key, index)=>(
                <label key={key}><input type="radio" name="category" onChange={selectCategory} value={index} checked={selectedCategory == index} />{key}</label>
              ))
            }
            
          </div>
          <div className="container_lang">
            <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => selectLang('en')}><span className="spsans">EN</span></button>
            <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => selectLang('kr')}><span className="spsans">KR</span></button>
          </div>
        </div>
        <section className="faq_items">
          {
            list.map((item,index)=><div key={index} className="faq_item">
              <button type="button" className={selectedItemIndex === index ? 'on ssone' : 'ssone'} onClick={()=>selectFaq(index)}><span className="spsans"><span className="text-blue spsans sssb">Q</span>{item.question}</span></button>
              <div className={`faq_item-toggle ${selectedItemIndex === index ? 'on' : ''}`} dangerouslySetInnerHTML={{__html: item.answer }}></div>
            </div>)
          }
        </section>
      </div>
    </div>
  );
}

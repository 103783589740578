import { useState } from 'react';
import iconApply from '@assets/images/icon_s1.svg';
import iconSerial from '@assets/images/icon_mail.svg';
import iconTicket from '@assets/images/icon_s3.svg';
import iconPrint from '@assets/images/icon_s4.svg';
import manualEN from '@assets/2024 IFA_Ticket Guide_ENG_0813.pdf'
import manualKR from '@assets/2024 IFA_Ticket Guide_KOR_0813.pdf'

export default function TicketInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit mb60">
        <h3></h3>
        <div className="container_lang">
        <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
        <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
      {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb20'>It is entirely your responsibility if the mail you entered is invaild. Please check thoroughly when filling out a request form.</p>
                <p className='mb20'>If you deliberately enter invalid email addresses to get extra tickets, or leak a voucher code to someone other than Samsung employees, we will charge you the cost and report related information to your division.</p>
              </div>
            </div>
            <div className="information_process">
              <div className="information_inner">
                <h4>Application Process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box">
                  <div className="mr10"><a href={manualEN} target="_blank" class="ssone ft_700 ft_blue_btn">* For detailed application instructions, refer to the PDF manual.</a></div>
                  <div className="process_item">
                    <span className="process_item-no">01</span>
                    <img src={iconApply} alt="reservation" className="mb30" />
                    <p><strong>Ticket Request</strong><span className="enter"></span>Enter the applicant’s information on the Ticket Request page.<br/>(*proxy application is possible)</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">02</span>
                    <img src={iconSerial} alt="reservation" className="mb30" />
                    <p><strong>Ticket Approval</strong><span className="enter"></span>Approval emails will be sent only to those traveling on business.</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">03</span>
                    <img src={iconTicket} alt="reservation" className="mb30" />
                    <p><strong>Ticket Delivery</strong><span className="enter"></span>IFA tickets will be sent via email to the approved travelers by IFA Management.</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">04</span>
                    <img src={iconPrint} alt="reservation" className="mb30" />
                    <p><strong>Print, Save QR Code, and Entrance</strong><span className="enter"></span>Tickets received via email must be printed or the QR code saved on a mobile device and bring it to the IFA venue.<br/>(On-site purchase or reissuance is not available)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_ticket">
              <div className="information_inner">
                <h4>Ticket Type</h4>
              </div>
              <div className="information_inner">
                <div className='information_type_process mb60'>
                  <div>
                    <p className='bro-ft mb10'>Construction period  &nbsp; <span className='ssone ft_blue'>(8/12~9/5)</span></p>
                    <p className='process_img'>Contractor badge</p>
                    <p className='process_tx'>※ Work completion needed by 10 AM on 9/5</p>
                  </div>
                  <div>
                    <p className='bro-ft mb10'>Exhibition period  &nbsp; <span className='ssone ft_blue'>(9/6~9/10)</span></p>
                    <p className='process_img process_img2'>Exhibitor badge</p>
                  </div>
                  <div>
                    <p className='bro-ft mb10'>Demolition period  &nbsp; <span className='ssone ft_blue'>(9/10 18:00~)</span></p>
                    <p className='process_img process_img3'>Contractor badge</p>
                  </div>
                </div>
                <div className="information_text">
                  <p className='mb30'>
                    [Exhibitor badge] for persons who need access to the exhibition preparation period and exhibition period,<br/>
                    For those who need access during the installation/removal period, please prepare to [Contractor badge].<br/>
                    <br/>
                    For inquiries related to the Contractor badge, please contact Cheil Worldwide's Heali Hwang Pro at <a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a><br/>
                    (Please contact us only through e-mail so that we can respond sequentially. When inquiring about a messenger, it may be difficult to answer.)
                  </p>
                  <table className="mb20">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Exhibitor badge (Official Ticket)</th>
                        <th scope="col">Contractor badge</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-subject">Purpose</td>
                        <td>Exhibition period (9/6 to 9/10) and before and after the exhibition period</td>
                        <td>Access to the IFA event venue before and after the exhibition (8/12~9/5, 9/10 18:00~)</td>
                      </tr>
                      <tr>
                        <td className="table-subject">Available Time</td>
                        <td>From 08:00 during the event period</td>
                        <td>No time limit. Need to share the schedule with late night work organizers.</td>
                      </tr>
                      <tr>
                        <td className="table-subject">Recommended Users</td>
                        <td>Participants and the persons concerned that are expected to attend the event</td>
                        <td>Participants and the persons concerned that are supposed to access the venue during the construction and demolition</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb20'>입력한 메일이 유효하지 않은 메일인 경우 책임지지 않으니, 신청 시 유의 부탁드립니다. </p>
                <p className='mb20'>협의된 출장자 대상 이상으로 티켓을 발급 받은 경우, 혹은 삼성전자 외부인에게 발급 링크를 유출했을 경우, 해당 사실 소속 사업부에 공유 및 비용 개별 청구 예정입니다.</p>
                {/* <p>
                  2차 바우처 코드 발급일인 8/19(금) 이후, 발급받으신 바우처 코드를 <a href="https://ifa.messeticket.berlin/" target="_blank" class="href">IFA 공식 티켓 사이트</a>에 등록하여야 티켓 발급이 완료됩니다.<br/>
                  바우처 코드는 입장 효력이 없으며, PDF 티켓 또는 프린트한 티켓을 지참하지 않으면 행사장 입장이 불가능합니다.<br/>
                  (개별 메일 인증 절차가 있으므로, IFA 공식 티켓 발급 단계부터는 대리 신청을 권장하지 않습니다.)
                </p> */}
              </div>
            </div>


            
            <div className="information_process">
              <div className="information_inner">
                <h4>Application Process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box">
                  <div className="mr10"><a href={manualKR} target="_blank" class="ssone ft_700 ft_blue_btn">* 상세 신청 방법은 PDF 매뉴얼 파일 참조</a></div>
                  <div className="process_item">
                    <span className="process_item-no">01</span>
                    <img src={iconApply} alt="reservation" className="mb30" />
                    <p>티켓 신청<span className="enter"></span>Ticket Request 페이지에서 티켓 신청인의 정보 입력<br/>(* 대리 신청 가능)</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">02</span>
                    <img src={iconSerial} alt="reservation" className="mb30" />
                    <p>티켓 신청 승인<span className="enter"></span>출장자에 한하여 티켓 신청 승인 메일 발송</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">03</span>
                    <img src={iconTicket} alt="reservation" className="mb30" />
                    <p>티켓 전송<span className="enter"></span>승인된 출장자에 한하여 IFA Management에서 신청 시 입력한 E-mail로 IFA 티켓 발송</p>
                  </div>
                  <div className="process_item">
                    <span className="process_item-no">04</span>
                    <img src={iconPrint} alt="reservation" className="mb30" />
                    <p>프린트, QR 코드 저장 및 입장<span className="enter"></span>메일로 받은 티켓은 프린트 또는 QR 코드를 모바일 기기 내 저장하여 행사장 지참 필수<br/>(현장 구매 혹은 현장 재발급 불가능)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_ticket">
              <div className="information_inner">
                <h4>Ticket Type</h4>
              </div>
              <div className="information_inner">
                <div className='information_type_process mb60'>
                  <div>
                    <p className='bro-ft mb10'>공사기간 &nbsp; <span className='ssone ft_blue'>(8/12~9/5)</span></p>
                    <p className='process_img'>Contractor badge</p>
                    <p className='process_tx'>※ 9/5 오전 10시까지 전시장 작업 완료 필요</p>
                  </div>
                  <div>
                    <p className='bro-ft mb10'>전시기간 &nbsp;  <span className='ssone ft_blue'>(9/6~9/10)</span></p>
                    <p className='process_img process_img2'>Exhibitor badge</p>
                  </div>
                  <div>
                    <p className='bro-ft mb10'>철거기간 &nbsp;  <span className='ssone ft_blue'>(9/10 18:00~)</span></p>
                    <p className='process_img process_img3'>Contractor badge</p>
                  </div>
                </div>
                <div className="information_text">
                  <p className='mb30'>
                    전시 준비 기간 및 전시 기간 모두 출입이 필요한 인원의 경우 Exhibitor badge,<br/>
                    설치/철거 기간만 출입이 필요한 인원의 경우 Contractor badge가 필요합니다.<br/>
                    <br/>
                    Contractor badge 관련 문의사항은 제일기획 황혜리 프로(<a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a>)에게 문의 바랍니다.<br/>
                    (순차적으로 답변드릴 수 있도록 메일을 통해서만 문의 부탁드립니다. 메신저 문의는 답변이 불가합니다)
                  </p>
                  <table className="mb20">
                    <thead>
                      <tr>
                        <th scope="col" style={{fontWeight:'normal'}}></th>
                        <th scope="col" style={{fontWeight:'normal'}}>Exhibitor badge (Official Ticket)</th>
                        <th scope="col" style={{fontWeight:'normal'}}>Contractor badge</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-subject">용도</td>
                        <td>전시 기간(9/6~9/10) 및 전시 기간 전후</td>
                        <td>
                          전시 기간 전후(8/12~9/5, 9/10 18:00~)<br />
                          설치 / 철거 기간 동안만 행사장 출입 가능
                        </td>
                      </tr>
                      <tr>
                        <td className="table-subject">출입 가능 시간</td>
                        <td>행사기간 중 08:00부터 출입 가능</td>
                        <td>출입 가능 시간 제한 없음<br/>단, 심야근무 주최 측 공유 필요</td>
                      </tr>
                      <tr>
                        <td className="table-subject">권장 사용자</td>
                        <td>IFA 행사 기간 내 참석 예정인 출장자 및 관계자</td>
                        <td>설치 / 철거 기간 중 출입이 필요한 관계자 
</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        )
      }
    </>
  );
}

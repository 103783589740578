import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import moment from 'moment-timezone';

import Layout from '@/screens/Layout';
import TicketHome from '@/screens/TicketHome';
import TourHome from '@/screens/TourHome';

import NotFound from '@/screens/NotFound';
import End from '@/screens/End';
import Error from '@/screens/Error';
import Faq from '@/screens/Faq';

import PrivateRoute from '@/components/PrivateRoute'

import Ticket from '@/screens/Ticket';
import TicketInfomation from '@/screens/Ticket/Infomation';
import TicketRequestTemp from '@/screens/Ticket/RequestTemp';
import TicketRequest from '@/screens/Ticket/Request';
import TicketApplicationList from '@/screens/Ticket/ApplicationList';

import Tour from '@/screens/Tour';
import TourInfomation from '@/screens/Tour/Infomation';
import TourRerservation from '@/screens/Tour/Reservation';
import TourRerservationTemp from '@/screens/Tour/ReservationTemp';
import TourReservationEdit from '@/screens/Tour/ReservationEdit';
import TourRerservationList from '@/screens/Tour/ReservationList';
import TourReservationDetail from '@/screens/Tour/ReservationDetail';

import AdminLogin from '@/screens/Admin/Login';
import Admin from '@/screens/Admin';
import AdminMemberList from '@/screens/Admin/Member/MemberList';
import AdminMemberEdit from '@/screens/Admin/Member/MemberEdit';
import AdminTicketList from '@/screens/Admin/Ticket/TicketList';
import AdminTicketEdit from '@/screens/Admin/Ticket/TicketEdit';
import AdminRoomReservation from '@/screens/Admin/Room/ReservationRequest';
import AdminRoomReservationList from '@/screens/Admin/Room/ReservationList';
import AdminRoomReservationEdit from '@/screens/Admin/Room/ReservationEdit';
import AdminRoomReservationDetail from '@/screens/Admin/Room/ReservationDetail';
import AdminRoomReservationTimeTable from '@/screens/Admin/Room/ReservationTimeTable';
import AdminRoomReservationTimeTableEdit from '@/screens/Admin/Room/ReservationTimeTableEdit';
import AdminTourList from '@/screens/Admin/Tour/TourList';
import AdminTourCalendar from '@/screens/Admin/Tour/TourCalendar';
import AdminTourReservation from '@/screens/Admin/Tour/TourReservation';
import AdminTourEdit from '@/screens/Admin/Tour/TourEdit';
import AdminTourDetail from '@/screens/Admin/Tour/TourDetail';

import Signup from '@/screens/Account/Signup';
import VerifyEmail from '@/screens/Account/VerifyEmail';
import MyPage from '@/screens/Account/My';
import history from '@/helpers/history';

function Router() {
  const currentUser = useSelector(state => state.user);
  const parameters = new URLSearchParams(window.location.search);
  
  const adminDefaultPath = { 'UserManager' : '/admin/member', 'TicketManager' : '/admin/ticket', 'RoomManager' : '/admin/room', 'VipManager' : '/admin/tour' }
  const adminTicketDefaultPath = { 'UserManager' : '/ticket/admin/member', 'TicketManager' : '/ticket/admin/ticket', 'RoomManager' : '/ticket/admin/room', 'VipManager' : '/ticket/admin/tour' }
  const adminVipDefaultPath = { 'UserManager' : '/viptour/admin/member', 'TicketManager' : '/viptour/admin/ticket', 'RoomManager' : '/viptour/admin/room', 'VipManager' : '/viptour/admin/tour' }

  // const { basePath } = useSelector((state) => state.common);

  const basePath = history.location.pathname.toLowerCase().indexOf('/viptour') > -1 ? 'viptour' : history.location.pathname.toLowerCase().indexOf('/ticket') > -1 ? 'ticket' : ''

  let element = useRoutes([
    { path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <TicketHome /> },
        { path: 'information', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? moment().tz("Asia/Seoul").isAfter('2024-08-12T01:00:00.000Z') ? <TicketInfomation /> : <Navigate to='/' /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/' /> },
        { path: 'request', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? moment().tz("Asia/Seoul").isAfter('2024-08-23T09:00:00.000Z') ? <TicketRequestTemp /> : <TicketRequest /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/' /> },
        { path: 'ticket-list', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? moment().tz("Asia/Seoul").isAfter('2024-08-12T01:00:00.000Z') ? <TicketApplicationList /> : <Navigate to='/' /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/' /> },
        { path: 'faq', element: moment().tz("Asia/Seoul").isAfter('2024-08-12T01:00:00.000Z') ? <PrivateRoute> <Faq /> </PrivateRoute> : <Navigate to='/' /> },
        { path: '/viptour/faq', element: <PrivateRoute> <Faq /> </PrivateRoute> },
        { path: 'viptour',
          children: [
            { index: true, element: <TourHome /> },
            { path: 'information', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? <TourInfomation /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/viptour' /> },
            { path: 'reservation', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? moment().tz("Asia/Seoul").isAfter('2024-08-23T07:00:00.000Z') && moment().tz("Asia/Seoul").isBefore('2024-08-24T01:00:00.000Z') ? <TourRerservationTemp /> : <TourRerservation /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/viptour' /> },
            { path: 'reservation-list', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? <TourRerservationList /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/viptour' /> },
            { path: 'reservation/:reservation_id', element: <TourReservationDetail /> },
            { path: 'reservation/:reservation_id/modify', element: currentUser?.isLoggedIn && currentUser.user?.isVerified ? <TourReservationEdit /> : currentUser?.isLoggedIn ? <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to='/viptour' /> }
          ]
        },
        { path: 'register', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <Navigate to={`/${basePath}`} /> : <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : moment().tz("Asia/Seoul").isAfter('2024-08-12T01:00:00.000Z') ? <Signup /> : <Navigate to='/' /> },
        { path: 'viptour/register', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <Navigate to={`/${basePath}`} /> : <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Signup /> },
        { path: 'verify-email', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <Navigate to={`/${basePath}`} /> : <VerifyEmail /> : parameters.get('token') ? <VerifyEmail /> : <Navigate to={`/${basePath}`} /> },
        { path: 'viptour/verify-email', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <Navigate to={`/${basePath}`} /> : <VerifyEmail /> : parameters.get('token') ? <VerifyEmail /> : <Navigate to={`/${basePath}`} /> },
        { path: 'mypage', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <MyPage /> : <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to={`/${basePath}`} /> },
        { path: 'viptour/mypage', element: currentUser?.isLoggedIn ? currentUser.user?.isVerified ? <MyPage /> : <Navigate to={basePath ? `/${basePath}/verify-email` : `/verify-email`} /> : <Navigate to={`/${basePath}`} /> },
      ]
    },
    { path: 'admin',
      element: <PrivateRoute redirectPath='/admin/login'> <Admin /> </PrivateRoute>,
      children: [
        { index: true, element: <Navigate to={adminDefaultPath[currentUser?.user?.permissions?.[0]] || '/'}  /> },
        { path: 'member', element: <PrivateRoute role="Admin" permissions={['UserManager']}> <AdminMemberList /> </PrivateRoute>},
        { path: 'member/:user_id', element: <PrivateRoute role="Admin" permissions={['UserManager']}> <AdminMemberEdit /> </PrivateRoute>},
        { path: 'ticket', element: <PrivateRoute role="Admin" permissions={['TicketManager']}> <AdminTicketList /> </PrivateRoute>},
        { path: 'ticket/:ticket_id/modify', element: <PrivateRoute role="Admin" permissions={['TicketManager']}> <AdminTicketEdit /> </PrivateRoute>},
        { path: 'tour', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourList /> </PrivateRoute>},
        { path: 'tour-calendar', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourCalendar /> </PrivateRoute>},
        { path: 'tour/reservation', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourReservation /> </PrivateRoute>},
        { path: 'tour/:reservation_id', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourDetail /> </PrivateRoute> },
        { path: 'tour/:reservation_id/modify', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourEdit /> </PrivateRoute>,},
      ]
    },
    { path: 'admin/login', element: !currentUser?.isLoggedIn ? <AdminLogin /> : currentUser?.isLoggedIn && currentUser.user?.role === 'Admin' ? <Navigate to='/admin' /> : !currentUser?.isLoggedIn ? <Navigate to='/admin/login' /> : <Navigate to={`/${basePath}`} />},
    { path: 'viptour/admin',
      element: <PrivateRoute redirectPath='/viptour/admin/login'> <Admin /> </PrivateRoute>,
      children: [
        { index: true, element: <Navigate to= {adminVipDefaultPath[currentUser?.user?.permissions?.[0]] || '/vip'}  /> },
        { path: 'member', element: <PrivateRoute role="Admin" permissions={['UserManager']}> <AdminMemberList /> </PrivateRoute>},
        { path: 'member/:user_id', element: <PrivateRoute role="Admin" permissions={['UserManager']}> <AdminMemberEdit /> </PrivateRoute>},
        { path: 'tour', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourList /> </PrivateRoute>},
        { path: 'tour-calendar', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourCalendar /> </PrivateRoute>},
        { path: 'tour/reservation', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourReservation /> </PrivateRoute>},
        { path: 'tour/:reservation_id', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourDetail /> </PrivateRoute> },
        { path: 'tour/:reservation_id/modify', element: <PrivateRoute role="Admin" permissions={['VipManager']}> <AdminTourEdit /> </PrivateRoute>,},
      ]
    },
    { path: 'viptour/admin/login', element: !currentUser?.isLoggedIn ? <AdminLogin /> : currentUser?.isLoggedIn && currentUser.user?.role === 'Admin' ? <Navigate to='/viptour/admin' /> : !currentUser?.isLoggedIn ? <Navigate to='//admin/login' /> : <Navigate to={`/${basePath}`} />},

    { path: 'not-found', element: <NotFound /> },
    { path: 'error', element: <Error /> },
    { path: '*', element: <Navigate to='/' /> }
  ]);

  return element;
}

export default Router;

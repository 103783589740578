import api from '@/services/api';
import queryString from 'query-string';

class AuthService {
  login(userId, password) {
    return api
      .post('/accounts/authenticate', {
        userId,
        password
      })
  }

  forgotPassword(params) {
    return api.post('/accounts/forgot-password', params)
  }

  register(params) {
    return api
      .post('/accounts/register', params)
  }

  updateAccount(userId, params) {
    return api
      .put(`/accounts/${userId}`, params)
  }

  refreshToken(){
    return api.post('/accounts/refresh-token')
  }

  me() {
    return api
      .get('/accounts/me')
  }

  user(user_id) {
    return api 
      .get(`/accounts/${user_id}`)
  }

  allUsers(params) {
    return api
      .get(`/accounts/all?${queryString.stringify(params)}`)
  }

  existsUserId(userId) {
    return api
      .get(`/accounts/${userId}/exists`)
  }

  existsEmail(email) {
    return api
      .get(`/accounts/emails/${email}/exists`)
  }

  existsMobile(mobile_number) {
    return api
      .get(`/accounts/mobiles/${mobile_number}/exists`)
  }

  verifyEmail(token) {
    return api
      .post(
        '/accounts/verify-email',
        { token }
      )
  }

  resendVerifyEmail(email) {
    return api
      .post(
        '/accounts/resend-verify-email',
        { email }
      )
  }
}

export default new AuthService();

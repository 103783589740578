import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import resetIcon from '@/assets/images/icon_refresh.png';


import { allTickets, approveTicket, declineTicket, sendMailTicketStatus, deleteManyTicket, downloadExcel, returnBuildUpTicket, returnOfficialTicket, approveBuildUpTicket, approveOfficialTicket } from '@/actions/ticket';

export default function AdminTicket() {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(useLocation().search);

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [cancelTicketInfo, setCancelTicketInfo] = useState({});
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);
  const [pageLimit, setPageLimit] = useState(searchParams.get('limit') || 20);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [searchTicketType, setSearchTicketType] = useState(searchParams.get('type') || 'officialTicket');
  const [searchTicketNo, setSearchTicketNo] = useState(searchParams.get('no') || '');
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') || '');
  const [searchText, setSearchText] = useState(searchParams.get('text') || '');
  const [searchReset, setSearchReset] = useState(false);

  const [isTicketRefusalModal, setIsTicketRefusalModal] = useState(false);
  const [isTicketApprovalModal, setIsTicketApprovalModal] = useState(false);
  const [isSendMailTicketInfoModal, setIsSendMailTicketInfoModal] = useState(false);
  const [isTicketDeleteModal, setIsTicketDeleteModal] = useState(false);

  const [isTicketCancelModal, setIsTicketCancelModal] = useState(false);

  const { basePath } = useSelector((state) => state.common);

  let num = 0;

  useEffect(() => {
    getTickets();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    handleSearch();
 },[searchTicketType, searchStatus])

 useEffect(() => {
  if(searchReset){
    handleSearch()
  }
}, [searchReset]);

  const getTickets = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(allTickets({
      page: currentPage,
      limit: pageLimit,
      searchTicketType,
      searchTicketNo,
      searchStatus,
      text: searchText
    }))
    .then((res) => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(_.sortBy(_.map(_.groupBy(res.docs, row => row.reqNo), (info, reqNo) => ({ info, reqNo }))));
      setCheckList([]);
      num = 0;
    })
  }

  const handleSearch = () => {
    const url = new URL(window.location);
    url.searchParams.set('type', searchTicketType);
    url.searchParams.set('no', searchTicketNo);
    url.searchParams.set('status', searchStatus);
    url.searchParams.set('text', searchText);
    url.searchParams.set('limit', pageLimit);
    url.searchParams.set('page', 1);
    window.history.pushState({}, '', url);
    
    setCurrentPage(1);
    getTickets();
  }

  const handlePageClick = (event) => {
    const url = new URL(window.location);
    url.searchParams.set('page', event.selected + 1);
    window.history.pushState({}, '', url);

    setCurrentPage(event.selected + 1);
  };

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onRefusalItemConfirm = () => {
    dispatch(declineTicket({
      ids: checkList
    }))
    .then((res) => {
      getTickets();
      setIsTicketRefusalModal(false);
      toast.success('Ticket was refused.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onApprovalItemConfirm = () => {
    dispatch(approveTicket({
      ids: checkList
    }))
    .then((res) => {
      getTickets();
      setIsTicketApprovalModal(false);
      toast.success('Ticket was approved.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onSendMailTicketStatusConfirm = () => {
    dispatch(sendMailTicketStatus({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      setIsSendMailTicketInfoModal(false);
      toast.success('You have successfully sent an email.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onDeleteItemConfirm = () => {
    dispatch(deleteManyTicket({
      ids: checkList
    }))
    .then((res) => {
      setIsTicketDeleteModal(false);
      getTickets();
      toast.success('Delete completed.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onCancelTicket = (id, type, no) => {
    setCancelTicketInfo({
      id,
      type,
      no
    })
    setIsTicketCancelModal(true)
  }

  const onApproveTicket = (id, type) => {
    if(type === 'Build-Up') {
      dispatch(approveBuildUpTicket(id)).then(res=>{
        getTickets();
        toast.success('Approve Build-Up.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
    } else if(type === 'Official') {
      dispatch(approveOfficialTicket(id)).then(res=>{
        getTickets();
        toast.success('Approve Official.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
    }
  }
  
  const onCancelTicketConfirm = () => {
    dispatch(cancelTicketInfo.type === 'Build-Up' ? returnBuildUpTicket(cancelTicketInfo.id) : returnOfficialTicket(cancelTicketInfo.id))
    .then((res) => {
      getTickets();
      setIsTicketCancelModal(false);
      toast.success('Ticket was canceled.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const displayEmail = winHtml => {
    const w= 800; 
    const h= 600;

    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: "text/html" })
    );

    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    const newWindow = window.open(winUrl, '이메일 발송내용', 
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `
    )
  }

  return (
    <div className='container_adm'>
      <div className='search_wrap mb40'>
        {/* <select className='select_adm w300x' onChange={e=>setSearchTicketType(e.target.value)}>
          <option value='officialTicket' selected={searchTicketType === 'officialTicket'}>Official Ticket</option>
          <option value='buildUpTicket' selected={searchTicketType === 'buildUpTicket'}>Build-up Ticket</option>
        </select>
        <div className='input_search-wrap'>
          <input type='text' className='input_search' onChange={e=>setSearchTicketNo(e.target.value)} placeholder='Ticket number' value={searchTicketNo} />
          <button type="button" onClick={handleSearch}></button>
        </div> */}
        <select className='select_adm w300x' onChange={e=>setSearchStatus(e.target.value)}>
          <option value='' selected={searchStatus === ''}>Status</option>
          <option value='In Progress' selected={searchStatus === 'In Progress'}>In Progress</option>
          <option value='Approved' selected={searchStatus === 'Approved'}>Approved</option>
          <option value='Canceled' selected={searchStatus === 'Canceled'}>Canceled</option>
          <option value='Declined' selected={searchStatus === 'Declined'}>Declined</option>
        </select>
        <div className='input_search-wrap'>
          <input type='text' className='input_search' onChange={e=>setSearchText(e.target.value)} placeholder='Name or ID' value={searchText}/>
          <button type="button" onClick={handleSearch}></button>
        </div>
        <button className='btn-reset' type="button" onClick={()=>{
          setSearchTicketType('officialTicket');
          setSearchTicketNo('');
          setSearchStatus('');
          setSearchText('');
          setSearchReset(true);
        }}><img src={resetIcon} alt='reset' /></button>
      </div>
      <div className='adm_title-wrap mb20'>
        <div>
          <h2 style={{display:'inline-block'}}>Application List</h2>
          <select className='select_adm w100x' onChange={onChangePageLimit}>
            <option value='20' selected={pageLimit === 20}>20</option>
            <option value='50' selected={pageLimit === 30}>50</option>
            <option value='100' selected={pageLimit === 40}>100</option>
          </select>
        </div>
        <button className='btn-excel' onClick={()=>dispatch(downloadExcel())}>Export to Excel</button>
      </div>
      {
        currentItems && (
          <>
  <table className='table_adm  mb40'>
        <thead>
          <tr>
            <th scope='col' style={{width:'40px'}} rowSpan={2}>NO</th>
            <th scope='col' style={{width:'40px'}} rowSpan={2}><input type='checkbox' onChange={onChangeAll} checked={checkList.length === idList.length} /></th>
            <th scope='col' rowSpan={2}>ID / Date</th>
            <th scope='col' colSpan={2}>Name</th>
            <th scope='col' rowSpan={2}>E-mail</th>
            <th scope='col' rowSpan={2}>Mobile Phone</th>
            <th scope='col' rowSpan={2}>Company</th>
            <th scope='col' rowSpan={2}>Division</th>
            <th scope='col' rowSpan={2}>Region / Organization</th>
            <th scope='col' rowSpan={2}>Status</th>
            {/* <th scope='col' style={{maxWidth:'300px'}}>Official Ticket no.<br/>Build-up Ticket no.</th> */}
            <th scope='col' rowSpan={2}>Modification</th>
            <th scope='col' rowSpan={2} style={{width:'40px'}}>Email</th>
          </tr>
          <tr>
            <th className='two'>First name</th>
            <th className='two'>Last name</th>
          </tr>
        </thead>
        <tbody>
          {
           currentItems.map((group, n) => (
            <React.Fragment key={n}>
                {
                  group.info.map((row, index) => (
                    <>
                    <tr key={index}>
                      <td>{totalItemCount - (currentPage - 1) * pageLimit - (num++)}</td>
                      <td><input type='checkbox' onChange={(e) => onChangeEach(e, row.id)} checked={checkList.includes(row.id)} /></td>
                      {index === 0 && <td rowSpan={group.info.length} className='table_point'><strong>{group.info[0].account.userId}</strong><Moment format='DD.MMM.YYYY'>{group.info[0].created}</Moment></td>}
                      <td className="nowrap">{row.firstName}</td>
                      <td className="nowrap">{row.lastName}</td>
                      <td className="nowrap">{row.email}</td>
                      <td className="nowrap">{row.countryCode} {row.mobile}</td>
                      <td className="nowrap">{row.company}</td>
                      <td className="nowrap">{row.division}</td>
                      <td className="nowrap">{row.region} / {row.organization}</td>
                      <td className="nowrap">{row.status}</td>
                      {/* <td className="nowrap">
                        <div className='td-justify'>
                          {row.officialTicket?.official_ticket_no || <>
                            {row.status !== 'Canceled' ? 
                              <button type="button" className="btn-cancel-s" onClick={()=>onApproveTicket(row.id, 'Official')}>Approve Official</button>
                             : <span>-</span>}
                          </>}
                          {row.officialTicket?.official_ticket_no && (<>&nbsp;<button type="button" className="btn-cancel-s" onClick={()=>onCancelTicket(row.id, 'Official', row.officialTicket?.official_ticket_no)}>Cancel</button></>)}
                        </div>
                        <p className="td_sp"></p>
                        <div className='td-justify'>
                          {row.buildUpTicket?.buildup_ticket_no || <>
                            {row.status !== 'Canceled' ? 
                              <button type="button" className="btn-cancel-s" onClick={()=>onApproveTicket(row.id, 'Build-Up')}>Approve Build-up</button>
                             : <span>-</span>}
                          </>}
                          {row.buildUpTicket?.buildup_ticket_no && (<>&nbsp;<button type="button" className="btn-cancel-s" onClick={()=>onCancelTicket(row.id, 'Build-Up', row.buildUpTicket?.buildup_ticket_no)}>Cancel</button></>)}
                        </div>
                      </td> */}
                      <td>
                        {row.status !== 'Canceled' ? (
                          <Link to={`/admin/ticket/${row.id}/modify`}>
                            <button className='btn-modify'><span className='spsans'>Modify</span></button>
                          </Link>
                        ) : (
                          <button className='btn-modify' disabled={true}><span className='spsans'>Modify</span></button>
                        )}
                      </td>
                      <td>
                        {
                          row?.emailHist?.length > 0 ? <>
                          <ul>
                            <li><a href='#' onClick={()=>displayEmail(row.emailHist[row.emailHist.length-1].html)}><Moment format='DD.MMM.YYYY HH:mm'>{row.emailHist[row.emailHist.length-1].created}</Moment></a></li>
                          </ul>
                          </> : '-'
                        }
                      </td>
                    </tr>
                    </>
                  ))
                }
             </React.Fragment>
            ))
          }
          {
            (currentItems && currentItems.length === 0) && (<tr>
              <td colSpan='10' className='no_data'>
                <p>No Data</p>
              </td>
            </tr>)
          }
        </tbody>
      </table>
      <div className='paging mb60'>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<span className='paging_end pl30'></span>}
          onPageChange={handlePageClick}
          forcePage={ currentPage - 1 }
          pageRangeDisplayed={3}
          pageCount={totalPageCount}
          previousLabel={<span className='paging_first pl30'></span>}
          renderOnZeroPageCount={null}
          activeLinkClassName="paging_num paging_current"
          pageLinkClassName="paging_num"
          breakLinkClassName="paging_num"
          previousLinkClassName="paging_num"
          nextLinkClassName="paging_num"
        />
      </div>
      <div className='buttons_center'>
          <button className='btn-cancel mr10' type='button' onClick={()=>setIsTicketDeleteModal(true)} disabled={checkList.length === 0}><span className='spsans'>Delete Selection</span></button>
          <button className='btn-submit mr10 bg_red' type='button' onClick={()=>setIsTicketRefusalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Refusal</span></button>
          <button className='btn-submit mr10' type='button' onClick={()=>setIsTicketApprovalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Approval</span></button>
          <button className='btn-mail' type='button' disabled={checkList.length === 0} onClick={()=>setIsSendMailTicketInfoModal(true)}><span className='spsans'>Send email</span></button>
      </div>
          </>
        )
      }
      <div className="modal fade in" id="modalCancel" style={{display: isTicketRefusalModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Refuse this Ticket?</strong>
              </div>
              <div className="modal-footer">
                <button type="button" style={{maxWidth:200}} className="btn-done ssone" onClick={()=>onRefusalItemConfirm()}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsTicketRefusalModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isTicketApprovalModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Approve this Ticket?</strong>
              </div>
              <div className="modal-footer">
                <button type="button" style={{maxWidth:200}} className="btn-done ssone" onClick={()=>onApprovalItemConfirm()}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsTicketApprovalModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isSendMailTicketInfoModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Would you like to send an email?</strong>
              </div>
              <div className="modal-footer">
                <button type="button" style={{maxWidth:200}} className="btn-done ssone" onClick={()=>onSendMailTicketStatusConfirm()}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsSendMailTicketInfoModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isTicketDeleteModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Are you sure you want to delete?</strong>
              </div>
              <div className="modal-footer">
                <button type="button" style={{maxWidth:200}} className="btn-done ssone" onClick={()=>onDeleteItemConfirm()}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsTicketDeleteModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div> 
      <div className="modal fade in" id="modalCancel" style={{display: isTicketCancelModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered" style={{width: '430px'}}>
            <div className="modal-content modal-content-p0">
              <div className="modal-body" style={{padding:'30px',minHeight:'0'}}>
                <strong>Are you sure you want to cancel?</strong>
                <p style={{marginTop:'10px'}}>
                  { cancelTicketInfo.type} Ticket No : <strong style={{color: 'blue'}}>{cancelTicketInfo.no}</strong>
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" style={{maxWidth:200}} className="btn-done ssone" onClick={onCancelTicketConfirm}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsTicketCancelModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

const faqEN = [
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Login] Is it impossible to use the site if I don\'t log in?',
    answer: 'This site is based on a member login, and you must log in to use it. If you are not a member, please use the Samsung Dotcom account address after completing your membership.',
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Membership registration] Is it only possible to register through Samsung Dotcom e-mail?',
    answer: `This site is operated for Samsung executives and business partners and can only be applied with Samsung Dotcom and (partner) Samsung Dotcom, Smartthings Dotcom IDs.<br/>
    When you apply, you will receive an approval email to the email address you entered, so please check the registered email.`,
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: 'Do I have to sign up for each menu of Ticket and VIP Tour?	',
    answer: 'No. You only need to register once, either on the Ticket site or the VIP Tour site.	',
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Membership registration] Do I need to sign up for each event such as CES, MWC, and IFA?	',
    answer: 'Yes, we do not keep membership information for each event, and the site is operated separately for each event, so you have to sign up for each event.	',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] What are the steps to apply for a ticket?	',
    answer: `
This requires two confirmations, staggered in time.<br/>
(1)Sign up as a member at the following address (<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) and apply for a ticket.<br/>
(2) Once you receive the application approval email, you will receive an IFA ticket email from IFA Official Management during the period of 8/00 to 8/00.<br/>
(3) Check to ensure that the personal information on the IFA ticket is correct.<br/>
(4) Save the QR code to your mobile device or print the ticket and bring it with you.<br/>
Please note that on-site ticket issuance will not be available.
`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] What do I need to enter the Berlin IFA event venue?',
    answer: `Please print your ticket from the IFA Official Management or save QR code on your mobile device and bring it to the event.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] Is there an additional charge when I apply for the ticket?',
    answer: 'No. This event is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: `[Subject of issuance] I'd like to know the scope of the person who can get the ticket.	`,
    answer: `This <span style="font-family: SamsungSharpSans-Medium;">Ticket Request</span> applies to employees who are engaged in an exhibition related to IFA 24 Samsung Electronics.<br/>
Applications for non-exhibition-related work at Samsung Hall or personnel other than executives and employees are prohibited. Also, tickets for separate corporate personnel are not supported.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: `[Subject of issuance] I'm eligible to receive a ticket but did not receive the approval email. Is there an additional way to apply?`,
    answer: `If you have not received  the approval email, please contact the person in charge (Heali Hwang Pro of Cheil Worldwide / <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>).`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Proxy request] Is it possible to apply on behalf of someone else?',
    answer: `
Yes, you can apply for someone else at the address below (<a class="ssone" href="${window.location.origin}/request" tagret="_blank">${window.location.origin}/request</a>) by adding the applicant information by clicking the <button type="button" class="btn-plus" style="position: relative;top:3px;margin:0 0 5px 5px;display:inline-block;width:20px;height:20px;background-position:50% 50%;background-size:60%;cursor:none;padding:0px;background-color:#fff;border-radius:3px;border:1px solid #aaa;"></button> button at the bottom of the [<span style="font-family: SamsungSharpSans-Medium;">Ticket Request</span>] page.<br />
For this site, the mailing service is registered with the applicant's email address rather than the intended recipient's email address.Therefore, please be aware that if you applied on behalf of someone else, you will need to confirm whether the intended recipient has received the IFA ticket email from IFA Official Management.<br />
Additionally, please note that any false applications or applications made on behalf of individuals who are not affiliated with Samsung may result in charges from the relevant department and notification of the issue.
    `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Proxy request] Is there anything to keep in mind when apply on behalf of someone else?',
    answer: 'For quick application, please maintain real-time contact between the applicant and the subject.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] Is it possible to enter the venue during periods other than the IFA official exhibition period (September 6th to September 10th)?',
    answer: `Yes. You can enter without time restrictions during the construction period before and after the exhibition period (August 12th~September 5th / September 10th after 6PM).<br/>
            However, for late-night work, please inform the organizers in advance.
            `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Cancellation of Reservation] business trip has been canceled. How can I cancel it?',
    answer: `(1)You can cancel your application when the <span style="font-family: SamsungSharpSans-Bold;">Status</span> button on the <span style="font-family: SamsungSharpSans-Medium;">Ticket List</span> screen (<a class="ssone" href="${window.location.origin}/ticket-list" tagret="_blank">${window.location.origin}/ticket-list</a>) shows <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background:#000;display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Click to cancel</span>.<br />
This indicates that the administrator has not yet sent the approval email, and the applicant can cancel immediately.
<br /><br />
(2) If the <span style="font-family: SamsungSharpSans-Bold;">Status</span> button shows <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background: var(--color-grey);display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Approved</span>, it means the administrator has sent the approval email, and the applicant cannot cancel the application.<br />
If you wish to cancel, please contact the responsible person (Hye-Ri Hwang at Cheil Worldwide, h0302.hwang@samsung.com) to request cancellation. Additionally, it is recommended to check for duplicate applications when canceling.
<br /><br />
(2-2) If you have already received the IFA ticket email from IFA Official Management, cancellation is not possible.
`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Modify reservation contents] The applicant information was entered incorrectly. How can I modify it?	',
    answer: `If you have entered incorrect information, please cancel and re-register only if the <span style="font-family: SamsungSharpSans-Bold;">Status</span> button on the <span style="font-family: SamsungSharpSans-Medium;">Ticket List</span> screen (<a class="ssone" href="${window.location.origin}/ticket-list" tagret="_blank">${window.location.origin}/ticket-list</a>) shows <span style="position: relative;top:-2px;margin:5px 3px 3px 5px;  background:#000;display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Click to cancel</span>.
<br />
If the <span style="font-family: SamsungSharpSans-Bold;">Status</span> button shows <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background: var(--color-grey);display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Approved</span>, please contact the responsible person (Heali Hwang Pro of Cheil Worldwide / <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>) for assistance.
`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to use it] Is there an additional charge when I make a reservation for the conference room?',
    answer: 'No. This event is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to use it] How many hours a day can I use it when I make a reservation for the conference room?',
    answer: 'The conference room is open for up to an hour. Also, you can apply for up to one case a day. You can make a reservation every 30 minutes, so please note this in mind.',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to apply] How can I make a reservation for the Business Lounge?',
    answer: `Reservations are made by Please click on the available time at the address below (<a href="${window.location.origin}/room/reservation/timetable" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/reservation/timetable</a>) and complete the required items on the reservation page. Once the reservation is completed, the reservation will be finally approved by the administrator. Once your reservation is finally approved, an approval email will be sent to the email you applied for, so please check your registered email.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[Reservation confirmation] I want to know the status of my reservation application.',
    answer: `When the reservation is complete, You can find information about your reservation at <a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) and see how it goes. In addition, if you complete the reservation, you will receive an approved email to the email address you entered with the approval of your administrator.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[Modify reservation contents] Information entered incorrectly. How do I fix it?',
    answer: `at the address below (<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) You can modify the information you entered on the detail page or cancel the reservation. However, the reservation date, time, and Business Lounge cannot be changed after approval, so you can cancel and rebook. Please make a careful reservation.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: 'Can I use it just by making a reservation?',
    answer: 'After making a reservation, it can be used with the approval of the manager, and the request may be rejected depending on the reservation situation. If you need to adjust the time, the person in charge will contact you separately. Once your reservation is finally approved, an approval email will be sent to the email you applied for, so please check your registered email.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'Is there any additional charge or qualification for VIP tour reservation?',
    answer: 'There is no additional charge, but VIP tour is only for reserved VIP guests who are hosted by Samsung executives higher than EVP or Head of Regional Office position.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'How can I reserve VIP Tour?',
    answer: `Please click the address below (<a href="${window.location.origin}/viptour/reservation" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation</a>) and complete all required field on the reservation page. Once the reservation is completed, the administrator will give final approval to your reservation. When it is approved, an approval email will be sent to the email you applied for, so please check your registered mail box.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'How many guests can attend the tour?',
    answer: 'Tours are limited to 15 people to maintain a pleasant tour experience for your guests.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'Is there language option for VIP Tour?',
    answer: 'Yes. You can choose between English or Korean.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'I want to know the status of my reservation.',
    answer: `When you submit your reservation form, you can find information about your reservation at (<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>) and see how it goes. Also, you may receive an approval email from administrator when your reservation gets final confirmation.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'I submitted incorrect information. How can I correct it?',
    answer: `If your reservation is in “in process” status, you can edit submitted information or cancel the reservation at <a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>. If your guest is not able to visit on reserved time, please be sure to notice to VIP Tour PIC(Soojeong Kim : <a href="mailto:soozeong.kim@samsung.com" style="color:#1268bd;text-decoration:underline">soozeong.kim@samsung.com</a> / Heali Hwang : <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>).`,
  }


];

export default faqEN;
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import regionData from '@/data/regionData';
import { toast } from 'react-toastify';

import { allUsers, updateAccount } from '@/actions/auth';
import resetIcon from '@/assets/images/icon_refresh.png';

export default function AdminMember() {
  const dispatch = useDispatch();
  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [idPasswordList, setIdPasswordList] = useState({});
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [region, setRegion] = useState('');
  const [organization, setOrganization] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchReset, setSearchReset] = useState(false); 

  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    getUsers();
  }, [currentPage, pageLimit, region, organization]);

  useEffect(() => {
    if(searchReset){
      handleSearch()
    }
  }, [searchReset]);

  const getUsers = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(allUsers({
      page: currentPage,
      limit: pageLimit,
      region,
      organization,
      text: searchText
    }))
    .then((res) => {
      setIdList(res.docs.map(item=>item.id))
      setIdPasswordList(res.docs.map(item=>{return {[item.id]: ''}}))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }

  const handleSearch = () => {
    setCurrentPage(1);
    getUsers();
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(parseInt(value));
    setCurrentPage(1);
  }

  const onChangeRegion = (e) => {
    const value = e.target.value || '';
    setRegion(value);
  }

  const onChangeOrganization = (e) => {
    const value = e.target.value || '';
    setOrganization(value);
  }

  const onChangeSearchText = (e) => {
    const value = e.target.value || '';
    setSearchText(value);
  }

  const onChangeIdPasswordList = (id, value) => {
    setIdPasswordList({
      ...idPasswordList,
      [id]: value
    })
  }

  const onChangePassword = () => {
    console.log(idPasswordList)
    for(let i=0; i<checkList.length; i++){
      if(idPasswordList[checkList[i]]){
        console.log('idPasswordList[checkList[i]', idPasswordList[checkList[i]])
        dispatch(updateAccount(checkList[i],{
          password: idPasswordList[checkList[i]],
          confirmPassword: idPasswordList[checkList[i]],
        }))
      }
    }
    setCheckList([]);
    setIdPasswordList({})
    toast.success('complete change password.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: true,
    });
  }

  return (
    <div className='container_adm'>
      <div className='search_wrap mb40'>
        <select className='select_adm w300x' onChange={onChangeRegion}>
          <option value='' selected={region === ''}>Region</option>
          {
            Object.keys(regionData).map(key => (
              <option value={key} key={key} selected={region === key}>{key}</option>
            ))
          }
        </select>
        <select className='select_adm w300x' onChange={onChangeOrganization}>
          <option value='' selected={organization === ''}>Organization</option>
          {
            Object.values(regionData).map(value => (
              Object.keys(value).map(key => (
                <option value={key} key={key} selected={organization === key}>{key}</option>
              ))
            ))
          }
        </select>
        <div className='input_search-wrap mr10'>
          <input type='text' className='input_search' onChange={onChangeSearchText} value={searchText} placeholder='Name or phone' />
          <button type="button" onClick={()=>handleSearch()}></button>
        </div>
        <button type="button" className="btn-reset" onClick={()=>{
          setRegion('');
          setOrganization('');
          setSearchText('');
          setSearchReset(true);
        }}><img src={resetIcon} alt='reset' /></button>
      </div>
      <div className='adm_title-wrap mb20'>
        <div>
          <h2>Member List</h2>
          <select className='select_adm w100x' onChange={onChangePageLimit}>
            <option value='20' selected={pageLimit === 20}>20</option>
            <option value='50' selected={pageLimit === 30}>50</option>
            <option value='100' selected={pageLimit === 40}>100</option>
          </select>
        </div>
      </div>
      {currentItems && (<>
      <table className='table_adm mb40'>
        <thead>
          <tr>
            <th scope='col' style={{width: 60}}>NO</th>
            <th scope='col' style={{width: 55}}><input type='checkbox' className='chk_black chk_white' onChange={onChangeAll} checked={checkList.length === idList.length} /></th>
            <th scope='col'>ID</th>
            <th scope='col'>Email</th>
            <th scope='col'>Name</th>
            <th scope='col'>Region</th>
            <th scope='col'>Organization</th>
            <th scope='col'>Mobile Phone</th>
            <th scope='col'>Change password</th>
            <th scope='col'>Date</th>
            <th scope='col'>Modification</th>
          </tr>
        </thead>
        <tbody>
          {
            currentItems.map((item, index)=>(
              <tr>
                <td>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                <td><input type='checkbox' className='chk_black' onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)} /></td>
                <td style={{whiteSpace:'nowrap'}}>{item.userId}</td>
                <td style={{whiteSpace:'nowrap'}}>{item.email}</td>
                <td style={{whiteSpace:'nowrap'}}>{item.firstName} {item.lastName}</td>
                <td>{item.region}</td>
                <td>{item.organization}</td>
                <td style={{whiteSpace:'nowrap'}}>{item.countryCode} {item.mobile}</td>
                <td><input type='text' className='input_changepw' placeholder='Change password' value={idPasswordList[item.id]} onChange={(e)=>onChangeIdPasswordList(item.id,e.target.value)}/></td>
                <td><Moment format='DD.MMM.YYYY'>{item.created}</Moment></td>
                <td><Link to={basePath ? `/${basePath}/admin/member/${item.id}` : `/admin/member/${item.id}`}><button className='btn-modify'><span className='spsans'>Modify</span></button></Link></td>
              </tr>
            ))
          }
          {
          (currentItems && currentItems.length === 0) && (<tr>
            <td colSpan='11' className='no_data'>
              <p>No Data</p>
            </td>
          </tr>)
          }
        </tbody>
      </table>
      <div className='paging mb60'>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<span className='paging_end pl30'></span>}
          onPageChange={handlePageClick}
          forcePage={ currentPage - 1 }
          pageRangeDisplayed={3}
          pageCount={totalPageCount}
          previousLabel={<span className='paging_first pr30'></span>}
          renderOnZeroPageCount={null}
          activeLinkClassName="paging_num paging_current"
          pageLinkClassName="paging_num"
          breakLinkClassName="paging_num"
          previousLinkClassName="paging_num"
          nextLinkClassName="paging_num"
        />
      </div>
      <div className='buttons_center'>
        {/* <button className='btn-cancel mr10' type='button'><span className='spsans'>Delete Selection</span></button> */}
        <button className='btn-submit' type='button' onClick={()=>onChangePassword()} disabled={checkList.length === 0}><span className='spsans'>Change Password</span></button>
      </div>
      </>)}
    </div>
  );
}

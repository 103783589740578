import { Outlet } from 'react-router-dom';
import AdminHeader from '@/screens/Admin/AdminHeader';
import AdminNavigationBar from '@/screens/Admin/AdminNavigationBar';
export default function Tour() {
  return (
    <>
      <AdminHeader />
      <AdminNavigationBar />
      <Outlet />
    </>
  );
}

const tourTime = [
  ...[6, 7, 8].flatMap(day => [
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '10:00 ~',
      label: '10:00 ~ 10:45'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '10:45 ~',
      label: '10:45 ~ 11:30'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '11:30 ~',
      label: '11:30 ~ 12:15'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '12:15 ~',
      label: '12:15 ~ 13:00'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '13:00 ~',
      label: '13:00 ~ 13:45'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '13:45 ~',
      label: '13:45 ~ 14:30'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '14:30 ~',
      label: '14:30 ~ 15:15'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '15:15 ~',
      label: '15:15 ~ 16:00'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '16:00 ~',
      label: '16:00 ~ 16:45'
    },
    {
      date: `2024-09-${String(day).padStart(2, '0')}`,
      time: '16:45 ~',
      label: '16:45 ~ 17:30'
    }
  ])
];

export default tourTime;
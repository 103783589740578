import { SET_BASE_PATH, CLEAR_BASE_PATH } from '@/actions/types';
import { SET_IS_LOADING } from '../actions/types';

const initialState = {
  isLoading: false,
  basePath: ''
};

export default function common(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING:
      return { 
        ...state,
        isLoading: payload
      }
    case SET_BASE_PATH:
      return { 
        ...state,
        basePath: payload.replace('/','') 
      }
    case CLEAR_BASE_PATH:
      return { 
        ...state,
        basePath: '' 
      }
    default:
      return state;
  }
}

const faqKR = [
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[로그인] 로그인을 하지 않으면 사이트 이용이 불가능한가요?',
    answer: '본 사이트는 회원 로그인을 기반으로 하는 사이트로, 반드시 로그인을 해주셔야 이용이 가능합니다. 회원이 아니신 분들은 보유하고 계신 삼성닷컴 계정 주소를 통해 회원가입을 완료한 후 이용해 주시기 바랍니다.',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] 회원 가입은 삼성닷컴 이메일로만 가능한가요?',
    answer: '	삼성 임직원 및 협력사를 대상으로 운영되는 사이트로 삼성닷컴, (파트너)삼성닷컴, 스마트싱스닷컴 아이디로만 신청이 가능합니다.<br> 신청 시 입력 해 주신 이메일 주소로 승인 메일이 발송되니 등록하신 이메일을 확인하시기 바랍니다.',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] Ticket, VIP Tour의 각 URL별로 회원가입을 따로 진행해야 하나요?',
    answer: '아니오. 회원가입은 Ticket 또는 VIP Tour URL 상관 없이 한 번만 진행해 주시면 됩니다',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] CES, MWC, IFA 등 각 행사마다 회원 가입을 새로 해야 하나요?',
    answer: '네. 행사 별 회원가입 정보를 보관하지 않으며, 사이트가 행사마다 별도 운영되기 때문에 각각 가입을 해주셔야 합니다.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 티켓 신청 단계는 어떻게 되나요?',
    answer: `시간 차를 두고, 총 두 번의 확인을 거쳐야 합니다.<br>(1) 아래 주소에서(<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) 회원 가입 후 티켓을 신청합니다.<br>(2) 신청 승인 메일을 받았다면, 8/00~8/00 기간 내 IFA Official Management로 부터 IFA 티켓 메일을 받게됩니다.<br>(3) IFA 티켓에 적용된 개인 정보가 맞는지 확인합니다.<br>(4) QR 코드를 모바일에 저장하거나 티켓을 출력하여 소지해 주시기 바랍니다.<br>현장에서는 티켓 발권이 불가능한 점 유의 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 베를린 IFA 행사장 입장 시에, 어떤 것들이 필요한가요?',
    answer: `IFA Official Management로 부터 받은 티켓은 PDF 프린트 혹은 모바일 기기 내 저장하여 행사장에 지참 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 티켓 신청 시, 별도의 비용이 발생하나요?',
    answer: `아니오. 본 행사는 임직원을 대상으로 진행되므로 별도의 비용이 발생하지 않습니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[발급대상] 티켓 발급 대상 범위가 궁금합니다.',
    answer: `해당 티켓은 IFA 24 삼성전자 전시 관련 업무를 진행하는 출장 임직원이 신청 가능한 티켓입니다. 삼성관 전시 관련 업무를 위한 신청이 아닌 경우, 혹은 임직원 외의 인원을 위한 신청은 금지합니다.<br>또한, 별도 법인 담당자측 티켓은 지원하지 않습니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[발급대상] 티켓 발급 대상자인데도, 승인 메일을 받지 못했습니다. 추가로 신청할 수 있는 방법이 있나요?	',
    answer: `승인 메일을 받지 못하신 경우, 담당자 (제일기획 황혜리 프로 <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>)에게 연락 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[대리신청] 대리 신청이 가능한가요?',
    answer: `네. 아래 주소에서 (<a class="ssone" href="${window.location.origin}/request" tagret="_blank">${window.location.origin}/request</a>), <span style="font-family: SamsungSharpSans-Medium;">Ticket Request</span> 페이지 하단의 <button type="button" class="btn-plus" style="position: relative;top:3px;margin:0 0 5px 5px;display:inline-block;width:20px;height:20px;background-position:50% 50%;background-size:60%;cursor:none;padding:0px;background-color:#fff;border-radius:3px;border:1px solid #aaa;"></button> 버튼을 통해 신청자 정보를 추가하는 방식으로 대리 신청이 가능합니다.<br />본 사이트 안내 및 승인 메일의 경우, 대상자가 아닌 신청자의 메일 주소로 메일링 서비스가 등록되오니 추후 대상자가 IFA Official Management로부터 IFA 티켓 메일을 받았는지 대리 신청자의 확인이 필요하니 이 점 유의 바랍니다. 또한, 허위 또는 삼성 관계자 외의 인원 대리 신청 시 사업부 청구 및 해당 사항 통보 예정이니 유의 바랍니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[대리신청] 대리 신청 시 유의할 점이 있나요?',
    answer: `빠른 신청을 위해 신청자와 대상자 간 연락 가능 상태를 유지해 주시기 바랍니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[티켓타입] IFA 공식 티켓 사이트에서 발급받은 티켓은 IFA 공식 전시 기간 (9월 6일~9월 10일) 외의 기간에도, 행사장 출입이 가능한가요?	',
    answer: `네. 전시 기간 전후 (8월 12일~9월 5일 / 9월 10일 오후 6시~)의 공사 기간 동안, 출입 가능 시간제한 없이 입장이 가능합니다.<br />단, 심야 근무의 경우 미리 주최 측에 공유 부탁드립니다.`,
  },
  // {
  //   categoryCode: 'ticket',
  //   category: 'Ticket',
  //   question: '[티켓타입] [Exhibitor Ticket]과 [Build-up Ticket] 바우처 코드를 모두 받았습니다. 하나만 필요한 경우에는, IFA 공식 티켓 사이트에 하나만 등록해서 사용해도 괜찮은가요?',
  //   answer: `네. 두 가지 코드 중 필요하신 코드만 등록하여 사용하시는 것이 가능합니다.`,
  // },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[예약내용취소] 출장이 취소되었습니다. 취소는 어떻게 하나요?',
    answer: `(1) 본 사이트 <span style="font-family: SamsungSharpSans-Medium;">Ticket List</span> (<a class="ssone" href="${window.location.origin}/ticket-list" tagret="_blank">${window.location.origin}/ticket-list</a>) 화면에서 <span style="font-family: SamsungSharpSans-Bold;">Status</span> 항목 버튼이  <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background:#000;display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Click to cancel</span>상태일 때 취소 가능합니다. <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background:#000;display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Click to cancel</span>상태는 아직 관리자가 승인 메일을 보내기 전 상태로, 신청자가 바로 취소할 수 있습니다. 
<br /><br />
(2) <span style="font-family: SamsungSharpSans-Bold;">Status</span> 항목 버튼이 <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background: var(--color-grey);display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Approved</span>상태는 관리자가 승인 메일을 전송한 상태로, 신청자가 취소할 수 없습니다.
취소를 희망하신다면, 담당자 (제일기획 황혜리 프로 <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>)에게 연락하여 취소 요청해 주시기 바랍니다.  
또한 취소 시에 중복 신청 검사를 권유 드립니다. 
<br /><br />
(2-2) 만일, IFA Official Management로부터 IFA 티켓 메일을 받은 상태라면, 취소할 수 없습니다.
    `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[예약내용수정] 신청자 정보를 잘못 입력하였습니다. 어떻게 수정할 수 있나요?	',
    answer: `
    
    
정보를 잘못 입력하신 경우, 본 사이트 <span style="font-family: SamsungSharpSans-Medium;">Ticket List</span> (<a class="ssone" href="${window.location.origin}/ticket-list" tagret="_blank">${window.location.origin}/ticket-list</a>)화면에서 화면에서 <span style="font-family: SamsungSharpSans-Bold;">Status</span> 항목 버튼이 <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background:#000;display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Click to cancel</span>상태인 경우에 한하여, 취소 후 재등록 부탁드립니다.<br />
<span style="font-family: SamsungSharpSans-Bold;">Status</span> 항목 버튼이 <span style="position: relative;top:-2px;margin:0 3px 3px 5px;  background: var(--color-grey);display:inline-block;font-size:0.9em;color:#fff;border-radius:35px;padding:4px 16px;font-weight:700;font-family: SamsungSharpSans-Bold;">Approved</span>상태인 경우에는 담당자 (제일기획 황혜리 프로 <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>)에게 연락 부탁드립니다.
    
    `,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[이용방법] 회의실 예약 시 별도의 비용이 발생하나요?',
    answer: '아니오. 본 행사는 임직원을 대상으로 진행되므로 별도의 비용이 발생하지 않습니다. ',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[이용방법] 회의실 예약 시 하루에 몇 시간 이용 가능한가요?',
    answer: '회의실은 최대 한 시간 이용이 가능합니다. 또한, 하루 최대 1건만 신청 가능합니다. 기본 30분 단위로 예약이 가능하니 이 점 참고 부탁드립니다. ',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[신청방법] 회의실 예약은 어떻게 진행되나요?',
    answer: `예약자는 아래 주소에서(<a href="${window.location.origin}/room/reservation/timetable" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/reservation/timetable</a>) 이용 가능한 시간을 클릭한 후에 예약 페이지에서 필수 항목을 작성해 주시면 됩니다. 예약자가 신청 완료 시, 관리자를 통해 예약이 최종 승인됩니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[예약확인] 내 예약 신청 현황을 알고 싶습니다.',
    answer: `예약이 완료되면 아래 주소에서(<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) 예약에 관한 정보, 진행 상황을 확인할 수 있습니다. 또한, 예약을 완료하신 경우, 관리자의 승인을 거쳐 입력하신 이메일 주소로 승인 완료 메일이 발송됩니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[예약내용수정] 정보를 잘못 기재했습니다. 수정은 어떻게 하나요?',
    answer: `아래 주소(<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>)의 상세페이지에서 입력하신 정보를 수정하거나 예약 취소를 할 수 있습니다. 단, 승인 이후에는 예약 날짜 및 시간과 회의실은 변경이 불가하기에 취소 후 재 예약하실 수 있습니다. 신중하게 예약해 주시기 바랍니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '예약만 하면 이용할 수 있는 건가요?',
    answer: '예약 후, 관리자의 승인을 통해 이용이 가능하며, 예약 상황에 따라 요청이 반려될 수 있습니다. 시간 조정이 필요한 경우 담당자가 따로 연락을 드립니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'VIP Tour 예약 시, 별도의 비용이나 자격요건이 있나요?',
    answer: 'VIP Tour는 별도의 비용이 발생하지 않습니다만, 부사장급/지역총괄급 이상 삼성 임원이 직접 호스팅 하시는 투어에 한해 신청이 가능합니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'VIP Tour 예약은 어떻게 진행되나요?',
    answer: `예약은 이 주소에서(<a href="${window.location.origin}/viptour/reservation" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation</a>) 신청하실 수 있습니다. 신청 양식을 제출하시더라도, 관리자가 예약을 최종 승인하여야 예약이 완료됩니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다.`,
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: '참가자는 최대 몇명까지 등록 가능한가요?',
    answer: '게스트 분들의 쾌적한 투어를 위하여 회당 투어 인원은 15인으로 제한하고 있습니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'VIP Tour 예약 시, 언어 선택이 가능한가요?',
    answer: '네, 가능합니다. 한국어/영어 두가지 중 한가지를 선택할 수 있습니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: '내 예약 신청 현황을 알고 싶습니다.',
    answer: `예약이 완료되면 이 주소에서(<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>)예약에 관한 정보, 진행 상황을 확인할 수 있습니다. 또한, 예약을 완료하신 경우, 관리자의 승인을 거쳐 입력하신 이메일 주소로 승인 완료 메일이 발송됩니다.`,
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: '정보를 잘못 기재했습니다. 수정은 어떻게 하나요?',
    answer: `최종 확정 전이라면 이 주소(<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>)상세페이지에서 입력하신 정보를 수정하거나 예약 취소를 할 수 있습니다. 부득이한 사정으로 최종 확정된 예약 일시에 방문이 어려우신 경우, 담당자(김수정 프로 : <a href="mailto:soozeong.kim@samsung.com" style="color:#1268bd;text-decoration:underline">soozeong.kim@samsung.com</a> / 황혜리 프로 : <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>)측에 반드시 연락 바랍니다.`,
  }
];

export default faqKR;

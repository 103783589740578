import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { logout } from "@/actions/auth";
import LogoIcon from '@/assets/images/logo-w.svg';
import LogoIconB from '@/assets/images/logo.svg';
import history from '@/helpers/history';
import moment from 'moment-timezone';

function Header(props) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);

  const { basePath } = useSelector((state) => state.common);

  const logOut = () => {
    dispatch(logout()).then(
      () => {
        history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
      }
    );
  };

  return (
    history.location.pathname.toLowerCase().indexOf('/vip') > -1 ? <>
      <header id="header" className={`${currentUser?.isLoggedIn ? '' : 'bg_white'}`}>
        <div className="header_logo">
          <Link to={`/${basePath}`}>{currentUser?.isLoggedIn ? <img src={LogoIcon} alt="SAMSUNG" /> : <img src={LogoIconB} alt="SAMSUNG" />}</Link>
        </div>
        {
          currentUser?.isLoggedIn ? 
            <>
              <div className="header_nav">
                <ul className='menu'>
                    <li className='depth1'>
                    <li><NavLink to="/viptour/information">Information</NavLink></li>
                    <li><NavLink to="/viptour/reservation">VIP Tour Reservation</NavLink></li>
                    <li><NavLink to="/viptour/reservation-list">Reservation List</NavLink></li>
                    </li>
                    <li><NavLink to="/viptour/faq" className="btn-faq"><span className="spsans">FAQ</span></NavLink></li>
                </ul>
              </div>
              <div className="header_links">
                { basePath ? <Link to={`/${basePath}/mypage`}>My Page</Link> : <Link to="/mypage">My Page</Link>}
                <span className="links-acc-bar"></span>
                <button type="button" onClick={()=>logOut()}>Logout</button>
              </div>
            </>
            :
            null
          }
      </header>
    </>
    :
      moment().tz("Asia/Seoul").isAfter('2024-08-12T01:00:00.000Z') && <>
      <header id="header" className={`${currentUser?.isLoggedIn ? '' : 'bg_white'}`}>
        <div className="header_logo">
          <Link to={`/${basePath}`}>{currentUser?.isLoggedIn ? <img src={LogoIcon} alt="SAMSUNG" /> : <img src={LogoIconB} alt="SAMSUNG" />}</Link>
        </div>
        {
          currentUser?.isLoggedIn ? 
            <>
              <div className="header_nav">
                <ul className='menu'>
                    <li className='depth1'>
                        <li><NavLink to="/information">Information</NavLink></li>
                        <li><NavLink to="/request">Ticket Request</NavLink></li>
                        <li><NavLink to="/ticket-list">Ticket List</NavLink></li>
                    </li>
                    <li><NavLink to="/faq" className="btn-faq"><span className="spsans">FAQ</span></NavLink></li>
                </ul>
              </div>
              <div className="header_links">
                { basePath ? <Link to={`/${basePath}/mypage`}>My Page</Link> : <Link to="/mypage">My Page</Link>}
                <span className="links-acc-bar"></span>
                <button type="button" onClick={()=>logOut()}>Logout</button>
              </div>
            </>
            :
            null
          }
      </header>
    </>
  );
}

export default Header;

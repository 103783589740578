import { useState } from 'react';
import tourMap from '@assets/images/tour-route_2024.jpeg';

export default function TourInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit mb60">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>- This is VIP tour reservation system for IFA 2024 Samsung booth.</p>
                <p className='mb10'>- VIP tour is only for reserved VIP guests who are hosted by Samsung executives higher than EVP or Head of Regional Office position.</p>
                <p className='mb10'>- The tour takes about 40 minutes.</p>
                <p className='mb10'>- Please be sure that after 10 minutes from your reserved time, it will be considered as a No-show and the tour will be canceled.</p>
                <p className='mb10'>- If your guest is not able to visit in reserved date and time, please be sure to notice it to VIP Tour PIC<br />&nbsp; (Soojeong Kim : <a href="mailto:soozeong.kim@samsung.com" className="ft_blue ssone">soozeong.kim@samsung.com</a> / Heali Hwang : <a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a>)</p>
                <p>- Tours are limited to 15 people to maintain a pleasant tour experience for your guests.</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Meeting Point</h4>
              </div>
              <div className="information_inner">
                <p className='mb40'>The tour will start from VIP Tour desk which is located at the main entrance on the right side of the Samsung booth on the 2nd floor of CCB(City Cube Berlin).</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>

            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour Route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Introduction  &nbsp;→&nbsp; SmartThings &nbsp;→&nbsp; Bespoke AI  &nbsp;→&nbsp; AI TV &nbsp;→&nbsp;  Galaxy AI &nbsp;→&nbsp; Ecosystem Wall</p>
              </div>
            </div>

          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>- IFA 2024 Samsung VIP 투어 예약 페이지에 오신 것을 환영합니다.</p>
                <p className='mb10'>- VIP 투어는 부사장급/지역총괄급 이상 삼성 임원이 직접 호스팅 하시는 투어에 한해 신청을 받고 있습니다.</p>
                <p className='mb10'>- 투어는 총 40분 소요됩니다.</p>
                <p className='mb10'>- 예약 시간으로부터 10분이 경과될때까지 방문 하지 않으실 경우, 노쇼로 간주되어 투어가 취소됩니다.</p>
                <p className='mb10'>- 부득이한 사정으로 최종 확정된 예약 일시에 방문이 어려우신 경우,<br /> &nbsp;&nbsp; 반드시 담당자 (김수정 프로 : <a href="mailto:soozeong.kim@samsung.com" className="ft_blue ssone">soozeong.kim@samsung.com</a> / 황혜리 프로 : <a href="mailto:h0302.hwang@samsung.com" className="ft_blue ssone">h0302.hwang@samsung.com</a>)측에 연락 바랍니다.</p>
                <p className='mb10'>- 게스트 분들의 쾌적한 투어를 위하여 회당 투어 인원은 15인으로 제한하고 있습니다.</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Meeting Point</h4>
              </div>
              <div className="information_inner">
                <p className='mb40'>투어는 VIP데스크 앞에서 시작되며, 데스크는 CCB(City Cube Berlin) 2층 삼성관 메인 입구 우측에 있습니다.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>

            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour Route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Introduction  &nbsp;→&nbsp; SmartThings &nbsp;→&nbsp; Bespoke AI  &nbsp;→&nbsp; AI TV &nbsp;→&nbsp;  Galaxy AI &nbsp;→&nbsp; Ecosystem Wall</p>
              </div>
            </div>

          </section>
        )
      }
    </>
  );
}

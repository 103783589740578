import api from '@/services/api';
import queryString from 'query-string';

class TourService {
  createReservation(params) {
    return api
      .post('/tours', params)
  }

  updateReservation(params) {
    return api
      .put(`/tours/${params.id}`, params)
  }

  updateReservationLabel(params) {
    return api
      .put(`/tours/${params.id}/label`, params)
  }

  updateReservationMemo(params) {
    return api
      .put(`/tours/${params.id}/memo`, params)
  }

  updateReservationPersonInCharge(params) {
    return api
      .put(`/tours/${params.id}/person-in-charge`, params)
  }

  sendMailReservationStatus(params) {
    return api.post('/tours/admin/status/send-mail', params)
  }

  cancelReservation(reservationId) {
    return api
      .put(`/tours/${reservationId}/cancel`)
  }

  approveReservation(params) {
    return api
      .put(`/tours/admin/approve`,params)
  }

  priorityReservation(params) {
    return api
      .put(`/tours/admin/priority`,params)
  }

  vvipReservation(params) {
    return api
      .put(`/tours/admin/vvip`,params)
  }

  inProgressReservation(params) {
    return api
      .put(`/tours/admin/inprogress`,params)
  }

  declineReservation(params) {
    return api
      .put(`/tours/admin/decline`,params)
  }

  deleteReservation(reservation_id) {
    return api
      .delete(`/tours/${reservation_id}`)
  }

  deleteManyReservation(params) {
    return api
      .post(`/tours/admin/delete`, params)
  }

  reservations(params) {
    return api
      .get(`/tours?${queryString.stringify(params)}`)
  }

  allReservations(params) {
    return api
      .get(`/tours/all?${queryString.stringify(params)}`)
  }

  reservation(reservation_id) {
    return api
      .get(`/tours/${reservation_id}`)
  }

  excel(params) {
    return api
      .get(`/tours/admin/excel?${queryString.stringify(params)}`,{responseType: 'blob'})
  }

  userReservations(userId) {
    return api
      .get(`/tours/${userId}`)
  }

  availableDateTime(params) {
    return api.get(`/tours/avaliable-datetime?${queryString.stringify(params)}`)
  }
}

export default new TourService();

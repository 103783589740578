import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import pointicon from '@assets/images/point-icon.svg';
import ReactToPrint from 'react-to-print';
import { cancelTicket, tickets, ticket } from '@/actions/ticket';
import { setIsLoading } from '@/actions/common';
import _, { set } from 'lodash';

export default function TicketApplicationList() {
  const dispatch = useDispatch();
  
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isTicketView, setIsTicketView] = useState(false);
  const [isTicketCancel, setIsTicketCancel] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState({});

  const [selectedItem, setSelectedItem] = useState(null);

  const componentRef = useRef(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(tickets({
      page: currentPage,
    }))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      dispatch(setIsLoading(false));
    })
  }, [currentPage]);

  const onOpenItem = (ticket_id) => {
    dispatch(ticket(ticket_id))
    .then((res) => {
      setSelectedItem(res);
      setIsTicketView(true);
    })
  }

  const onCancelItem = (ticket_id) => {
    // dispatch(ticket(ticket_id))
    // .then((res) => {
    //   setSelectedItem(res);
    //   setIsTicketCancel(true);
    // })
    setSelectedItem({
      id: ticket_id
    });
    setIsTicketCancel(true);
  }

  const onCancelItemConfirm = () => {
    dispatch(cancelTicket(selectedItem.id))
    .then(res => {
      const index = _.findIndex(currentItems, {id: selectedItem.id});

      currentItems.splice(index, 1, {...currentItems[index], status: 'Canceled'});

      setIsTicketCancel(false);
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const btnIfa = () => {
    window.open('https://www.ifa-berlin-2023-exhib.reg.buzz/step/1?type=exhibitor&exhib=416b5110-0610-11ee-a433-000000000000', '_blank')
  }

  const onTogglePopover = (e, id) => {
    e.stopPropagation();
    setPopoverVisible(prev => ({
      [id]: !prev[id]
    }));
  };  

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setPopoverVisible({});
  };

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Ticket List</h2>
        </div>
      </div>
      <div className='container_body-tit'>
        <h3>Reservation List</h3>
        {/* <button className='btn-ifa' onClick={btnIfa}><span className='spsans'>IFA TICKET REGISTRATION SITE</span></button> */}
      </div>
      <section className='application-list'>
      <table className='table_list'>
        <colgroup>
          <col width={'10%'}/>
          <col width={'15%'}/>
          <col width={'15%'}/>
          <col width={'20%'}/>
          <col width={'20%'}/>
          <col width={'10%'}/>
          <col width={'20%'}/>
        </colgroup>
        <thead>
          <tr>
            <th scope='col'>NO.</th>
            <th scope='col'>Date</th>
            <th scope='col'>Name</th>
            <th scope='col'>Company</th>
            <th scope='col'>Division</th>
            <th scope='col'>Detail</th>
            <th scope='col'>Status</th>
          </tr>
        </thead>
        <tbody>
        {
          currentItems && currentItems.map((item, index)=>(
          <tr key={item.id}>
            <td>{totalItemCount - (currentPage - 1) * 10 - index}</td>
            <td><Moment format='DD.MMM.YYYY'>{item.created}</Moment></td>
            <td>{item.firstName} {item.lastName}</td>
            <td>{item.company}</td>
            <td>{item.division}</td>
            <td>{item.status !== 'Canceled' && (<button className='btn-view' onClick={()=>onOpenItem(item.id)}><span className='spsans'>View</span></button>)}</td>
            <td className="btn-container">
                <button 
                  className='btn-cancel-r'  
                  onClick={() => onCancelItem(item.id)} 
                  disabled={item.status !== 'In Progress'}
                >
                  <span className='spsans'>{item.status === 'In Progress' ? 'Click to cancel' : item.status}</span>
                </button>
                <div className="icon-container" onClick={(e) => onTogglePopover(e, item.id)}>
                  <img src={pointicon} alt="info" className="info-icon" />
                  {popoverVisible[item.id] && (
                    <>
                      {item.status ==  'In Progress' && <div className="popover">
                        취소 및 취소 후 재등록 가능<br/>
                        You can cancel and re-register afterward
                      </div>}
                      {item.status == 'Approved' && <div className="popover">
                        승인 완료<br/>
                        Approval completed
                      </div>}
                      {item.status ==  'Declined' && <div className="popover">
                        관리자 승인 거절<br/>
                        Denied by Administrator
                      </div>}
                      {item.status ==  'Canceled' && <div className="popover">
                        신청자 취소<br/>
                        Canceled by Applicant
                      </div>}
                    </>
                  )}
                </div>
            </td>
          </tr>
          ))
        }
        {
          (currentItems && currentItems.length === 0) && (<tr>
            <td colSpan='7' className='no_data'>
              <p>No Data</p>
            </td>
          </tr>)
        }
        </tbody>
      </table>
      <div className='paging'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<span className='paging_end pl30'></span>}
          onPageChange={handlePageClick}
          forcePage={ currentPage - 1 }
          pageRangeDisplayed={3}
          pageCount={totalPageCount}
          previousLabel={<span className='paging_first pl30'></span>}
          renderOnZeroPageCount={null}
          activeLinkClassName='paging_num paging_current'
          pageLinkClassName='paging_num'
          breakLinkClassName='paging_num'
          previousLinkClassName='paging_num'
          nextLinkClassName='paging_num'
        />
      </div>
    </section>

  <div className='modal fade in' id='modalTicketView' style={{display: isTicketView ? 'block' : 'none' }}>
      <div className='modal-dialog modal-dialog_lg modal-dialog-centered'>
        <div className='modal-content'>
          <ReactToPrint
            trigger={() => <button className='btn-print'></button>}
            content={() => componentRef.current}
          />
          <button className='btn-modalclose' onClick={()=>setIsTicketView(false)}></button>
          <div ref={componentRef}>
            <div className='modal-header'>
                <h3 className='modal-title'>Ticket Request</h3>
            </div>
            <div className='modal-body'>
              {
                selectedItem &&
                (
                    <ul className='modal_view clear'>
                      <li className='view-half'>
                        <p className='modal_view_name'>Name<span></span>이름</p>
                        <p className='modal_value'>{selectedItem.firstName} {selectedItem.lastName}</p>
                      </li>
                      <li className='view-half'>
                        <p className='modal_view_name'>Email<span></span>이메일</p>
                        <p className='modal_value'>{selectedItem.email}</p>
                      </li>
                      <li className='view-half'>
                        <p className='modal_view_name'>Company/Division<span></span>회사/사업부</p>
                        <p className='modal_value'>{selectedItem.company} / {selectedItem.division}</p>
                      </li>
                      <li className='view-half'>
                        <p className='modal_view_name'>Region/Organization<span></span>지역/법인</p>
                        <p className='modal_value'>{selectedItem.region} / {selectedItem.organization}</p>
                      </li>
                      <li className='view-half'>
                        <p className='modal_view_name'>Mobile<span></span>연락처</p>
                        <p className='modal_value'>{selectedItem.countryCode} {selectedItem.mobile}</p>
                      </li>
                    </ul>
                )
              }
            </div>
          </div>
          {/* <div className='modal-footer'>
            <a href='https://www.ifa-berlin-2023-exhib.reg.buzz/step/1?type=exhibitor&exhib=416b5110-0610-11ee-a433-000000000000' target="_blank" className='btn-modalifa'><span className='spsans'>GO TO SITE</span></a>
          </div> */}
        </div>
      </div>
  </div>

  <div className='modal fade in' id='modalCancel' style={{display: isTicketCancel ? 'block' : 'none'}}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content modal-content-p0'>
          <div className='modal-body'>
            <p className="mb10">Are you sure you want to cancel this?</p>
            <p className='fw-md'>정말 취소하시겠습니까?</p>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn-cancel mr20 sssb' onClick={()=>setIsTicketCancel(false)}>No</button>
            <button type='button' className='btn-done sssb' style={{maxWidth:200,marginRight:0}} onClick={()=>onCancelItemConfirm()}>Yes</button>
          </div>
        </div>
      </div>
  </div>
    </>
  );
}

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoIcon from '@/assets/images/logo.svg';

function AdminHeader() {
  const { basePath } = useSelector((state) => state.common);

  return (
    <header id='header' className='admin'>
      <div className='header_logo'>
        <Link to={`/${basePath}`}><img src={LogoIcon} alt='SAMSUNG' /></Link>
      </div>
    </header>
  );
}

export default AdminHeader;
